import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getRequest, putRequest } from "../utils/api";
import Preloader from "../shared/Preloader";
import style from "./CategoriesCards.module.css";

import codingCard from "../images/codingCard.png";
import mathsButton from "../images/maths.svg";
import codingButton from "../images/coding.svg";
import quizzes from "../images/games.svg";
import liveclasses from "../images/live.svg";
import lms from "../images/lms.svg";
import latestnews from "../images/news.svg";
import teachers from "../images/teachers.svg";
import tuitions from "../images/tuitions.svg";
import videos from "../images/videos.svg";
import games from "../images/games.gif";
import competitions from "../images/competitions.gif";
import content from "../images/content.gif";

import { Eye, MousePointer } from "react-feather";
import Grow from "@material-ui/core/Grow";

const Maths = () => {
  const [mathsCardArr, setMathsCardArr] = useState([]);
  const [codingCardsArr, setCodingCardArr] = useState(null);
  const [showMaths, setShowMaths] = useState(true);
  const [showCoding, setShowCoding] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const activeButton = useParams();

  function handleShowMaths() {
    setShowCoding(false);
    setShowMaths(true);
    history.push("/maths");
  }

  function handleShowCoding() {
    setShowMaths(false);
    setShowCoding(true);
    history.push("/coding");
  }

  useEffect(() => {
    if (activeButton.activeButton === "maths") {
      setShowCoding(false);
      setShowMaths(true);
    } else if (activeButton.activeButton === "coding") {
      setShowMaths(false);
      setShowCoding(true);
    } else {
      setShowCoding(false);
      setShowMaths(true);
    }
    setLoading(true);
    getRequest("/categories")
      .then((res) => {
        let arrCategories = res.data.arrCategories;
        let mathsData = arrCategories.filter((item) => {
          return item.type === "maths";
        });
        let codingData = arrCategories.filter((item) => {
          return item.type === "coding";
        });

        let finalMathsData = [...mathsData];
        let imagePath;
        for (let i = 0; i < finalMathsData.length; i++) {
          if (finalMathsData[i].name === "Games") {
            imagePath = games;
          } else if (finalMathsData[i].name === "Quizzes") {
            imagePath = quizzes;
          } else if (finalMathsData[i].name === "Videos") {
            imagePath = videos;
          } else if (finalMathsData[i].name === "LMS") {
            imagePath = lms;
          } else if (finalMathsData[i].name === "Competitions") {
            imagePath = competitions;
          } else if (finalMathsData[i].name === "Teachers") {
            imagePath = teachers;
          } else if (finalMathsData[i].name === "Live Classes") {
            imagePath = liveclasses;
          } else if (finalMathsData[i].name === "Content") {
            imagePath = content;
          } else if (finalMathsData[i].name === "Tuitions") {
            imagePath = tuitions;
          } else if (finalMathsData[i].name === "Latest News") {
            imagePath = latestnews;
          }
          finalMathsData[i].image = imagePath;
        }
        setMathsCardArr(finalMathsData);

        console.log(codingData);
        let finalCodingData = [...codingData];
        let codingBackgroundColor;

        for (let i = 0; i < finalCodingData.length; i++) {
          if (finalCodingData[i].name === "Games & Quizzes") {
            codingBackgroundColor = "#ffe3e7";
          } else if (finalCodingData[i].name === "Videos") {
            codingBackgroundColor = "#e4f294";
          } else if (finalCodingData[i].name === "Training Institutes") {
            codingBackgroundColor = "#d9ffdf";
          } else if (finalCodingData[i].name === "Competitions") {
            codingBackgroundColor = "#fedecb";
          } else if (finalCodingData[i].name === "Teachers") {
            codingBackgroundColor = "#ffe5ac";
          } else if (finalCodingData[i].name === "Live Classes") {
            codingBackgroundColor = "#c8e9ff";
          } else if (finalCodingData[i].name === "Content") {
            codingBackgroundColor = "#e4c9fe";
          } else if (finalCodingData[i].name === "Online Trainings") {
            codingBackgroundColor = "#ffdbed";
          } else if (finalCodingData[i].name === "Latest News") {
            codingBackgroundColor = "#fdffe4";
          } else if (finalCodingData[i].name === "LMS") {
            codingBackgroundColor = "#f3dcff";
          }

          finalCodingData[i].color = codingBackgroundColor;
          finalCodingData[i].image = imagePath;
        }
        setCodingCardArr(finalCodingData);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function handleCardClick(e) {
    let gameId = e.uid;
    let data = {
      category: gameId,
    };
    putRequest("/category/count", data)
      .then((res) => {
        history.push(`/${e.type}/${gameId}`);
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className={style.wrapper}>
          <div className={style.controller}>
            <button className={`${style.mathsButton} ${showMaths ? style.active : ""}`} onClick={handleShowMaths}>
              <img src={mathsButton} alt="" />
              Maths
            </button>
            <button className={`${style.codingButton} ${showCoding ? style.active : ""}`} onClick={handleShowCoding}>
              {/* <div className={style.disabled}></div> */}
              <img src={codingButton} alt="" />
              Coding
            </button>
          </div>

          <div className={style.cards}>
            {showMaths ? (
              <>
                {mathsCardArr.map((card) => {
                  return (
                    <Grow
                      key={card.uid}
                      in={showMaths}
                      style={{ transformOrigin: "0 0 0" }}
                      {...(showMaths ? { timeout: 1000 } : {})}
                    >
                      <div
                        key={card.uid}
                        className={style.card}
                        id={card.uid}
                        onClick={handleCardClick.bind(this, card)}
                      >
                        <div className={style.top}>
                          <img src={card.image} alt="" />
                        </div>
                        <div className={style.bottom}>
                          <p className={style.title}>{card.name}</p>
                          <p className={style.count}>
                            {card.name === "Games" || card.title === "Quizzes" ? <MousePointer /> : <Eye />}{" "}
                            {card.count}
                          </p>
                        </div>
                      </div>
                    </Grow>
                  );
                })}
              </>
            ) : null}

            {showCoding ? (
              <>
                {codingCardsArr.map((card) => {
                  return (
                    <Grow
                      in={showCoding}
                      style={{ transformOrigin: "0 0 0" }}
                      {...(showCoding ? { timeout: 1000 } : {})}
                    >
                      <div
                        key={card.uid}
                        className={`${style.codingCard}`}
                        id={card.uid}
                        onClick={handleCardClick.bind(this, card)}
                      >
                        <div className={style.colorCoded} style={{ backgroundColor: `${card.color}` }}>
                          <p className={style.cardName}>{card.name}</p>
                          <p className={style.cardCount}>
                            {card.name === "Games" || card.name === "Quizzes" ? <MousePointer /> : <Eye />}
                            {card.count}
                          </p>
                        </div>
                      </div>
                    </Grow>
                  );
                })}
              </>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default Maths;
