import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { getRequest } from "../utils/api.js";
import Slider from "react-slick";
import SecondaryNav from "../Main/SecondaryNav";
import Footer from "../Main/Footer";
import Button from "../shared/Button";

import style from "./RootCard.module.css";
import searchStyle from "./SearchResult.module.css";
import mathsButton from "../images/maths.svg";
import codingButton from "../images/coding.svg";

import { ChevronRight, ChevronLeft, Star, Eye, Search, X } from "react-feather";

import whiteCard from "../images/whiteCard.png";

import { withStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import { CircularProgress } from "@material-ui/core";
import { keys } from "@material-ui/core/styles/createBreakpoints";

const StyledRating = withStyles({
  iconFilled: {
    color: "#6a2c70",
  },
})(Rating);

const arrowStyle = {
  boxSizing: "border-box",
  width: "100px",
  height: "240px",
  marginTop: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundImage: "linear-gradient(to left, rgba(89, 89, 89, 0.3), rgba(196, 196, 196, 0))",
  iconNext: {
    backgroundColor: "#ffffff",
    width: "50px",
    height: "50px",
    borderRadius: "100%",
    stroke: "#000000",
    marginLeft: "10px",
  },
  prev: {
    width: "100px",
    height: "240px",
    marginTop: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: "linear-gradient(to right, rgba(89, 89, 89, 0.3), rgba(196, 196, 196, 0))",
  },
  iconPrev: {
    backgroundColor: "#ffffff",
    width: "50px",
    height: "50px",
    borderRadius: "100%",
    stroke: "#000000",
    marginRight: "20px",
  },
};

function CustomArrowNext(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={arrowStyle} onClick={onClick}>
      <ChevronRight style={arrowStyle.iconNext} />
    </div>
  );
}

function CustomArrowPrev(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={arrowStyle.prev} onClick={onClick}>
      <ChevronLeft style={arrowStyle.iconPrev} />
    </div>
  );
}

const RootCard = () => {
  const [searchData, setSearchData] = useState([]);
  const [mathsData, setMathsData] = useState([]);
  const [codingData, setCodingData] = useState([]);
  const [showMaths, setShowMaths] = useState(true);
  const [showCoding, setShowCoding] = useState(false);
  const [isMathsEmpty, setIsMathsEmpty] = useState(false);
  const [isCodingEmpty, setIsCodingEmpty] = useState(false);
  const [mathsCount, setMathsCount] = useState(0);
  const [codingCount, setCodingCount] = useState(0);
  const [empty, setEmpty] = useState(false);
  const [searchString, setSearchString] = useState({});
  const [loading, setLoading] = useState(true);
  const [formDataSearch, setFormDataSearch] = useState({});
  const [searchCount, setSearchCount] = useState(null);
  const searchBar = useRef();
  const history = useHistory();

  function callSearch() {
    let queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    setSearchString(params.get("s"));
    let str = params.get("s");
    getRequest(`/search/resources/${str}`).then((resp) => {
      let data = resp.data;
      let objToArr = [];

      for (let [key, value] of Object.entries(data)) {
        let entry = {};
        entry[key] = value;
        objToArr.push(entry);
      }
      let newMathsData = [];
      let newCodingData = [];
      for (let i = 0; i < objToArr.length; i++) {
        for (let [key, value] of Object.entries(objToArr[i])) {
          let mathsEntry = {};
          let codingEntry = {};
          if (value.length > 0) {
            mathsEntry[key] = value.filter((item) => {
              return item.type === "maths";
            });
            codingEntry[key] = value.filter((item) => {
              return item.type === "coding";
            });
            newMathsData.push(mathsEntry);
            newCodingData.push(codingEntry);
          }
        }
      }
      if (newMathsData.length === 0) {
        setIsMathsEmpty(true);
        console.log("maths empty");
      } else {
        setMathsData(newMathsData);
      }
      if (newCodingData.length === 0) {
        setIsCodingEmpty(true);
      } else {
        setCodingData(newCodingData);
      }

      console.log(newMathsData);
      console.log(newCodingData);
      setSearchData(data);

      let mathsEmptyCounter = 0;
      let updateMathsCount = 0;
      let codingEmptyCounter = 0;
      let updateCodingCount = 0;
      for (let i = 0; i < newMathsData.length; i++) {
        updateMathsCount += Object.entries(newMathsData[i])[0][1].length;
        if (Object.entries(newMathsData[i])[0][1].length === 0) {
          mathsEmptyCounter++;
        }
        if (newMathsData.length === mathsEmptyCounter) {
          console.log(newMathsData.length);
          console.log(mathsEmptyCounter);
          setIsMathsEmpty(true);
        }
      }
      setMathsCount(updateMathsCount);

      for (let i = 0; i < newCodingData.length; i++) {
        updateCodingCount += Object.entries(newCodingData[i])[0][1].length;
        if (Object.entries(newCodingData[i])[0][1].length === 0) {
          codingEmptyCounter++;
        }
        if (newCodingData.length === codingEmptyCounter) {
          setIsCodingEmpty(true);
        }
      }
      setCodingCount(updateCodingCount);

      if (Object.keys(data).length === 0 && data.constructor === Object) {
        setEmpty(true);
      } else {
        setEmpty(false);
        setSearchCount(Object.entries(data)[0][1][0].totalLength);
      }
      setLoading(false);
    });
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    nextArrow: <CustomArrowNext />,
    prevArrow: <CustomArrowPrev />,
    // variableWidth: true,
    responsive: [
      {
        breakpoint: 1610,
        settings: {
          slidesToShow: 4,
          variableWidth: true,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          variableWidth: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          variableWidth: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          variableWidth: true,
        },
      },
    ],
  };

  function handleCardClick(card) {
    let url = card.url;
    window.open(url);
  }

  function goBack() {
    history.goBack();
  }

  function handleChangeInputSearch(e) {
    let newData = { ...formDataSearch };
    newData[e.target.name] = e.target.value;
    setFormDataSearch(newData);
    console.log(newData);
    if (e.target.value === "") {
      setFormDataSearch({});
    }
  }

  function handleSubmitInputSearch(e) {
    e.preventDefault();
    history.push(`/search?s=${formDataSearch.searchString}`);
    setLoading(true);
    setIsMathsEmpty(false);
    setIsCodingEmpty(false);
    setMathsData([]);
    setCodingData([]);
    callSearch();
  }

  function handleClearSearch() {
    searchBar.current.value = "";
    setFormDataSearch({});
  }

  function handleShowMaths() {
    setShowCoding(false);
    setShowMaths(true);
  }

  function handleShowCoding() {
    setShowMaths(false);
    setShowCoding(true);
  }

  useEffect(() => {
    callSearch();
  }, []);

  return (
    <>
      <SecondaryNav page="Search Result" />
      <div className={style.wrapper}>
        <div className={searchStyle.heroSearch}>
          <div className={searchStyle.searchBar}>
            <Search />
            <form onChange={handleChangeInputSearch} onSubmit={handleSubmitInputSearch}>
              <input ref={searchBar} type="text" name="searchString" placeholder="Search for topics, websites & more" />
            </form>
            {Object.keys(formDataSearch).length === 0 && formDataSearch.constructor === Object ? null : (
              <X style={{ cursor: "pointer" }} onClick={handleClearSearch} />
            )}
          </div>
        </div>
        {loading ? (
          <div className={style.loader}>
            <CircularProgress color="secondary" size="70px" />
          </div>
        ) : (
          <>
            {!empty ? (
              <div className={searchStyle.searchCount}>
                <p>Search results ({searchCount})</p>
              </div>
            ) : (
              <div className={style.empty}>
                No results found
                <Button onClick={goBack} title="Go Back" radius="5" />
              </div>
            )}
          </>
        )}

        {!empty ? (
          <div className={searchStyle.controller}>
            <button
              className={`${searchStyle.mathsButton} ${showMaths ? searchStyle.active : ""}`}
              onClick={handleShowMaths}
            >
              <img src={mathsButton} alt="" />
              Maths
              <p>({mathsCount})</p>
            </button>
            <button
              className={`${searchStyle.codingButton} ${showCoding ? searchStyle.active : ""}`}
              onClick={handleShowCoding}
            >
              {/* <div className={style.disabled}></div> */}
              <img src={codingButton} alt="" />
              Coding
              <p>({codingCount})</p>
            </button>
          </div>
        ) : null}

        {showMaths && mathsData.length > 0 ? (
          <>
            {mathsData.map((card) => {
              return (
                <>
                  {Object.entries(card).map((item) => {
                    return (
                      <>
                        {item[1].length > 0 ? (
                          <div className={style.section}>
                            <p className={style.sectionTitle}>{item[0]}</p>
                            <div className={style.carouselWrapper} style={{ padding: "0 20px" }}>
                              <Slider {...settings}>
                                {item[1].map((card) => {
                                  return (
                                    <div key={card.uid} onClick={handleCardClick.bind(this, card)}>
                                      {/* slick requires h3 */}
                                      <h3>
                                        <div key={card.title} className={searchStyle.card}>
                                          <div className={searchStyle.image}>
                                            {/* <img src={card.img_url ? `${baseUrl.baseUrl}/${card.img_url}` : placeholder} alt="" /> */}
                                            <img src={whiteCard} alt="" />
                                            <p className={searchStyle.title}>{card.name}</p>
                                          </div>
                                          <div className={searchStyle.body}>
                                            <p className={searchStyle.info}>{card.description}</p>
                                          </div>
                                          <div className={searchStyle.ratings}>
                                            <div className={searchStyle.stars}>
                                              <StyledRating size="small" name="read-only" value={card.stars} readOnly />
                                            </div>
                                            <div className={searchStyle.likes}>
                                              <Eye />
                                              {card.count}
                                            </div>
                                          </div>
                                        </div>
                                      </h3>
                                    </div>
                                  );
                                })}
                              </Slider>
                            </div>
                          </div>
                        ) : null}
                      </>
                    );
                  })}
                </>
              );
            })}
          </>
        ) : null}

        {showMaths && isMathsEmpty && !empty ? (
          <div className={style.empty}>
            No maths results found
            <Button onClick={goBack} title="Go Back" radius="5" />
          </div>
        ) : null}

        {showCoding && codingData.length > 0 ? (
          <>
            {codingData.map((card) => {
              return (
                <>
                  {Object.entries(card).map((item) => {
                    return (
                      <>
                        {item[1].length > 0 ? (
                          <div className={style.section}>
                            <p className={style.sectionTitle}>{item[0]}</p>
                            <div className={style.carouselWrapper} style={{ padding: "0 20px" }}>
                              <Slider {...settings}>
                                {item[1].map((card) => {
                                  return (
                                    <div key={card.uid} onClick={handleCardClick.bind(this, card)}>
                                      {/* slick requires h3 */}
                                      <h3>
                                        <div key={card.title} className={searchStyle.card}>
                                          <div className={searchStyle.image}>
                                            {/* <img src={card.img_url ? `${baseUrl.baseUrl}/${card.img_url}` : placeholder} alt="" /> */}
                                            <img src={whiteCard} alt="" />
                                            <p className={searchStyle.title}>{card.name}</p>
                                          </div>
                                          <div className={searchStyle.body}>
                                            <p className={searchStyle.info}>{card.description}</p>
                                          </div>
                                          <div className={searchStyle.ratings}>
                                            <div className={searchStyle.stars}>
                                              <StyledRating size="small" name="read-only" value={card.stars} readOnly />
                                            </div>
                                            <div className={searchStyle.likes}>
                                              <Eye />
                                              {card.count}
                                            </div>
                                          </div>
                                        </div>
                                      </h3>
                                    </div>
                                  );
                                })}
                              </Slider>
                            </div>
                          </div>
                        ) : null}
                      </>
                    );
                  })}
                </>
              );
            })}
          </>
        ) : null}

        {showCoding && isCodingEmpty && !empty ? (
          <div className={style.empty}>
            No coding results found
            <Button onClick={goBack} title="Go Back" radius="5" />
          </div>
        ) : null}

        {/* {Object.entries(searchData).map((card) => {
          return (
            <>
              <div className={style.section}>
                <p className={style.sectionTitle}>{card[0]}</p>
                <div className={style.carouselWrapper} style={{ padding: "0 20px" }}>
                  <Slider {...settings}>
                    {card[1].map((card) => {
                      return (
                        <div key={card.uid} onClick={handleCardClick.bind(this, card)}>
                          <h3>
                            <div key={card.title} className={searchStyle.card}>
                              <div className={searchStyle.image}>
                                <img src={whiteCard} alt="" />
                                <p className={searchStyle.title}>{card.name}</p>
                              </div>
                              <div className={searchStyle.body}>
                                <p className={searchStyle.info}>{card.description}</p>
                              </div>
                              <div className={searchStyle.ratings}>
                                <div className={searchStyle.stars}>
                                  <StyledRating size="small" name="read-only" value={card.stars} readOnly />
                                </div>
                                <div className={searchStyle.likes}>
                                  <Eye />
                                  {card.count}
                                </div>
                              </div>
                            </div>
                          </h3>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </>
          );
        })} */}
      </div>
      <Footer />
    </>
  );
};

export default RootCard;
