import React from "react";
import { useHistory } from "react-router-dom";
import style from "./Navbar.module.css";
import Button from "../shared/Button";
import { User } from "react-feather";

const Navbar = (props) => {
  const history = useHistory();
  function handleLogoClick() {
    history.push("/");
  }

  return (
    <div className={`${style.nav} ${!props.isStatic ? null : style.centerLogo}`}>
      <div className={style.logo} onClick={handleLogoClick}>
        <span>Maths</span>to<span>Code</span>.com
      </div>
      {!props.isStatic ? (
        <>
          {/* <div className={style.controllerDesktop}>
            <Button title="Partners" background="white-pink" radius="25" />
            <Button title="Users" background="pink" radius="25" />
          </div>
          <div className={style.controllerMobile}>
            <button>
              <User />
              Log In
            </button>
          </div> */}
        </>
      ) : null}
    </div>
  );
};

export default Navbar;
