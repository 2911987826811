import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CacheBuster from "./CacheBuster";
import App from "./App";

const Container = () => {
  function renderApp() {
    return <App />;
  }
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return (
          <Router>
            <Switch>
              <Route path="/" render={renderApp} />
            </Switch>
          </Router>
        );
      }}
    </CacheBuster>
  );
};

export default Container;
