import React, { useState, useRef } from "react";

import style from "./Home.module.css";
import heroImgOne from "../images/topleft.svg";
import heroImgTwo from "../images/topright.svg";
import heroTriangle from "../images/mobileTriangle.svg";
import heroSquare from "../images/mobileSquare.svg";
import heroBottom from "../images/mobileBottom.svg";
import rocket from "../images/rocket.svg";
import mathsFloating from "../images/mathsFloating.svg";
import google from "../images/google.png";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";

import { ChevronsLeft, Search, X } from "react-feather";
import { ChevronRight } from "react-feather";
import { ChevronLeft } from "react-feather";

import CategoriesCards from "./CategoriesCards";
import Button from "../shared/Button";
import Footer from "./Footer";
import Modal from "../shared/Modal";
import { useHistory } from "react-router-dom";

const selectStyle = makeStyles(() => ({
  wrapper: {
    padding: "5px 10px",
    background: "#f0f0f0",
    "&:focus": {
      backgroundColor: "#000000 !important",
    },
  },
  underline: {
    display: "none",
  },
  selected: {
    "&:focus": {
      backgroundColor: "#000000 !important",
    },
  },
}));

const selectTheme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      root: {
        width: "100%",
        borderRadius: "5px",
        padding: "7px !important",
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "#f0f0f0",
        },
      },
    },
  },
});

function Home() {
  const [knowMoreModal, setKnowMoreModal] = useState(false);
  const [assessmentLoginModal, setAssessmentLoginModal] = useState(false);
  const [beginTestModal, setBeginTestModal] = useState(false);
  const [floatingDivOpen, setFloatingDivOpen] = useState(true);
  const [profession, setProfession] = useState("student");
  const [subject, setSubject] = useState("maths");
  const [formDataSearch, setFormDataSearch] = useState({});
  const select = selectStyle();
  const searchBar = useRef();

  function handleProfession(e) {
    setProfession(e.target.value);
  }

  function handleSubject(e) {
    setSubject(e.target.value);
  }

  function handleKnowMoreModal() {
    setKnowMoreModal((prev) => (prev = !prev));
  }

  function handleAssessmentLoginModal() {
    setAssessmentLoginModal((prev) => (prev = !prev));
  }

  function handleFloatingDiv() {
    setFloatingDivOpen((prev) => (prev = !prev));
  }

  function handleBeginTestModal() {
    setBeginTestModal((prev) => (prev = !prev));
  }

  function handleChangeInputSearch(e) {
    let newData = { ...formDataSearch };
    newData[e.target.name] = e.target.value;
    setFormDataSearch(newData);
    console.log(newData);
    if (e.target.value === "") {
      setFormDataSearch({});
    }
  }

  const history = useHistory();
  function handleSubmitInputSearch(e) {
    e.preventDefault();
    history.push(`/search?s=${formDataSearch.searchString}`);
  }

  function handleClearSearch() {
    searchBar.current.value = "";
    setFormDataSearch({});
  }

  return (
    <>
      <div className="App">
        <div className={style.container}>
          <div className={style.wrapper}>
            <img className={style.heroLeft} src={heroImgOne} alt="" />
            <img className={style.heroRight} src={heroImgTwo} alt="" />
            <img className={`${style.heroMobile} ${style.heroTriangle}`} src={heroTriangle} alt="" />
            <img className={`${style.heroMobile} ${style.heroSquare}`} src={heroSquare} alt="" />
            <img className={`${style.heroMobile} ${style.heroBottom}`} src={heroBottom} alt="" />
            <div className={style.hero}>
              <p>
                Maths <span>&</span> Code Encyclopedia
              </p>
              <p className={style.subTitle}>One stop information hub for students, teachers & parents</p>
              <div className={style.heroSearch}>
                <div className={style.searchBar}>
                  <Search />
                  <form onChange={handleChangeInputSearch} onSubmit={handleSubmitInputSearch}>
                    <input
                      ref={searchBar}
                      type="text"
                      name="searchString"
                      placeholder="Search for topics, websites & more"
                    />
                  </form>
                  {Object.keys(formDataSearch).length === 0 && formDataSearch.constructor === Object ? null : (
                    <X style={{ cursor: "pointer" }} onClick={handleClearSearch} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={style.cardSection}>
            <CategoriesCards />
          </div>
        </div>
        <div className={style.preFooter}>
          <div className={style.mission}>
            <div className={style.missionTitle}>
              <img src={rocket} alt="" />
              Our Mission
            </div>
            <p>To provide relevant & updated information around maths & coding</p>
            <Button
              className={style.buttonOverride}
              onClick={handleKnowMoreModal}
              title="Know More"
              arrow="true"
              background="white-pink"
              radius="25"
            />
          </div>
          <div className={style.line}></div>
          {/* <div className={style.register}>
            <p>Want to join us as a partner & list your offerings?</p>
            <Button
              className={style.buttonOverride}
              title="Register Now"
              arrow="true"
              background="white-pink"
              radius="25"
            />
          </div> */}
        </div>
      </div>
      {/* <div className={style.footerContainer}>
        <Footer />
      </div> */}

      {/* Self Assessment FLoating DIV */}
      {/* <div className={`${style.floatingContainer} ${floatingDivOpen ? null : style.floatingContainerClose}`}>
        <div className={style.close} onClick={handleFloatingDiv}>
          {floatingDivOpen ? <ChevronRight /> : <ChevronLeft />}
        </div>
        <div className={style.floatingTitle}>
          <div className={style.topFloating}>
            <p>
              Take Self
              <br />
              Assessment
            </p>
            <img src={mathsFloating} alt="" />
          </div>
          <p>Know where you stand</p>
        </div>
        <Button
          onClick={handleAssessmentLoginModal}
          className={style.flatingButton}
          title="Start Now"
          background="pink"
          radius="25"
        />
      </div> */}

      {/* Know More Modal */}
      <Modal open={knowMoreModal} onClose={handleKnowMoreModal} title="Our Mission">
        <p className={style.modalText}>
          The purpose is that maths and code is important for the young generation to undderstand.
        </p>
        <p className={style.modalText}>
          Students aren't aware of where to find the right information at one stop, there are many resources available
          online. Also parents don't have much time to dig more.
        </p>
        <p className={style.modalText}>
          We aim to provide correct information in less amount of time, so the focus is more on engagement and learning
          and not on searching, which is a non value adding activity.
        </p>
        <p className={style.modalText}>
          MathstoCode is committed to provide you the most relavant information within the least amount of search time.
        </p>
      </Modal>

      {/* Self Assessment Login Modal */}
      {/* <Modal className={style.assessmentPaper} open={assessmentLoginModal} onClose={handleAssessmentLoginModal}>
        <div className={style.assessmentLoginWrapper}>
          <p className={style.assessmentTitle}>Take Self Assessment</p>
          <p className={style.modalText}>
            We help you understand where you stand today by answering few questions in maths or coding.
          </p>
          <div className={style.googleWrapper}>
            <p>To begin assessment</p>
            <button onClick={handleBeginTestModal}>
              <img src={google} alt="" />
              <p>Sign In with Google</p>
            </button>
          </div>
        </div>
      </Modal> */}

      {/* Begin Test Form Modal */}
      {/* <Modal className={style.assessmentPaper} open={beginTestModal} onClose={handleBeginTestModal}>
        <div className={style.beginTestWrapper}>
          <p>Self Assessment</p>
          <p className={style.greeting}>Hi, Shwet</p>
          <div className={style.testFormWrapper}>
            <div className={style.formGroup}>
              <div className={style.field}>
                <label>Phone Number</label>
                <input type="number" />
              </div>
              <div className={style.field}>
                <label>Profession</label>
                <MuiThemeProvider theme={selectTheme}>
                  <Select className={select.wrapper} value={profession} onChange={handleProfession} disableUnderline>
                    <MenuItem classes={{ select: select.selected }} value="student">
                      Student
                    </MenuItem>
                    <MenuItem classes={{ select: select.selected }} value="teacher">
                      Teacher
                    </MenuItem>
                  </Select>
                </MuiThemeProvider>
              </div>
            </div>
            <div className={style.formGroup}>
              <div className={style.field}>
                <label>Subject</label>
                <MuiThemeProvider theme={selectTheme}>
                  <Select className={select.wrapper} value={subject} onChange={handleSubject} disableUnderline>
                    <MenuItem classes={{ select: select.selected }} value="maths">
                      Maths
                    </MenuItem>
                    <MenuItem classes={{ select: select.selected }} value="coding">
                      Coding
                    </MenuItem>
                  </Select>
                </MuiThemeProvider>
              </div>
              <div className={style.field}>
                <Button className={style.testButton} title="Begin Test" background="pink" radius="5" />
              </div>
            </div>
          </div>
        </div>
      </Modal> */}
      <Footer isHome={true} />
    </>
  );
}

export default Home;
