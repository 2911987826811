import React from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import Home from "./Home";
import Navbar from "./Navbar";
import Quiz from "../Assessment/Quiz";
import RootCard from "../Categories/RootCard";
import "./App.css";
import SearchResult from "../Categories/SearchResult";

const App = () => {
  let page = window.location.href.split("/")[3];
  const history = useHistory();

  function renderHome() {
    history.push("/maths");
  }

  return (
    <div className="rootApp">
      <Navbar isStatic={page === "quiz" ? true : false} />
      <Switch>
        <Route path="/" exact render={renderHome} />
        <Route path="/search" exact component={SearchResult} />
        <Route path="/:activeButton" exact component={Home} />
        <Route path="/quiz" exact component={Quiz} />
        <Route path="/:category/:id" exact component={RootCard} />
      </Switch>
    </div>
  );
};

export default App;
