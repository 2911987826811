import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import style from "./SecondaryNav.module.css";
import { getRequest } from "../utils/api";
import { ArrowLeftCircle } from "react-feather";

const SecondaryNav = (props) => {
  const [pages, setPages] = useState([]);
  const [breadcrumb, setBreadcrumb] = useState(null);
  const resourceId = useParams();

  useEffect(() => {
    getRequest("/categories")
      .then((res) => {
        let arrCategories = res.data.arrCategories;
        let mathsPages = arrCategories.filter((item) => {
          return item.type === "maths";
        });
        let codingPages = arrCategories.filter((item) => {
          return item.type === "coding";
        });

        let mathsArr = [];
        for (let i = 0; i < mathsPages.length; i++) {
          let obj = {
            uid: mathsPages[i].uid,
            name: "Maths " + mathsPages[i].name,
          };
          let obj2 = {
            uid: codingPages[i].uid,
            name: "Coding " + codingPages[i].name,
          };
          mathsArr.push(obj);
          mathsArr.push(obj2);
          setPages(mathsArr);
        }
        let pageId = resourceId.id;
        for (let i = 0; i < mathsArr.length; i++) {
          if (pageId === mathsArr[i].uid) {
            setBreadcrumb(mathsArr[i]);
          }
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const history = useHistory();
  function handleBack() {
    if (resourceId.category) {
      history.push(`/${resourceId.category}`);
    } else {
      history.push("/");
    }
  }

  return (
    <div className={style.wrapper}>
      <button onClick={handleBack}>
        <ArrowLeftCircle />
      </button>
      <button>
        <p>{props.page}</p>
      </button>
      {breadcrumb ? <p className={style.breadcrumb}>{breadcrumb.name}</p> : null}
    </div>
  );
};

export default SecondaryNav;
