import React from "react";
import style from "./Result.module.css";
import { Doughnut } from "react-chartjs-2";

const Result = () => {
  const data = {
    labels: ["Incorrect", "Correct"],
    datasets: [
      {
        label: "# of Votes",
        data: [50, 50],
        backgroundColor: ["#EC4036", "#75C716"],
        borderColor: ["#EC4036", "#75C716"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className={style.wrapper}>
      <div className={style.text}>
        <div className={style.section}>
          <p className={style.title}>Result</p>
          <p>You have scored 4/6</p>
        </div>
        <div className={style.section}>
          <p className={style.title}>Analysis</p>
          <p>You have intermediate maths skills, more improvement required in geometry & algebra.</p>
        </div>
      </div>

      <div className={style.chartContainer}>
        <Doughnut
          data={data}
          options={{
            responsive: true,
            maintainAspectRatio: true,
          }}
        />
      </div>
    </div>
  );
};

export default Result;
