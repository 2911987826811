import React from "react";
import style from "./Footer.module.css";
import { useHistory } from "react-router-dom";
import linkedin from "../images/linkedin.svg";
import facebook from "../images/facebook.svg";
import instagram from "../images/instagram.svg";
import twitter from "../images/twitter.svg";

const Footer = (props) => {
  const history = useHistory();
  function handleHome() {
    if (props.isHome) {
      window.scrollTo(0, 0);
    } else {
      history.push("/");
    }
  }

  return (
    <div className={style.wrapper}>
      <div className={style.logo}>
        <span>Maths</span>to<span>Code</span>.com
      </div>
      <div className={style.links}>
        <p onClick={handleHome}>Home</p>
        <p>Terms & Conditions</p>
        <p>Privacy Policy</p>
        {/* <p>Contact Us</p> */}
      </div>
      <div className={style.socials}>
        <p>Follow us on</p>
        <div className={style.social}>
          <img src={linkedin} alt="" />
          <img src={facebook} alt="" />
          <img src={instagram} alt="" />
          <img src={twitter} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
