import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getRequest, putRequest, multiGetRequest } from "../utils/api";
import Button from "../shared/Button";
import Footer from "../Main/Footer";
import SecondaryNav from "../Main/SecondaryNav";
import style from "./RootCard.module.css";

import Slider from "react-slick";
import { ChevronRight, ChevronLeft, Eye } from "react-feather";

import coming from "../images/comingSoon.svg";
import whiteCard from "../images/whiteCard.png";
import blueCard from "../images/blueCard.png";
import greenCard from "../images/greenCard.png";
import pinkCard from "../images/pinkCard.png";

import { withStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import { CircularProgress } from "@material-ui/core";

const StyledRating = withStyles({
  iconFilled: {
    color: "#6a2c70",
  },
})(Rating);

const arrowStyle = {
  boxSizing: "border-box",
  width: "100px",
  height: "240px",
  marginTop: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundImage: "linear-gradient(to left, rgba(89, 89, 89, 0.3), rgba(196, 196, 196, 0))",
  iconNext: {
    backgroundColor: "#ffffff",
    width: "50px",
    height: "50px",
    borderRadius: "100%",
    stroke: "#000000",
    marginLeft: "10px",
  },
  prev: {
    width: "100px",
    height: "240px",
    marginTop: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: "linear-gradient(to right, rgba(89, 89, 89, 0.3), rgba(196, 196, 196, 0))",
  },
  iconPrev: {
    backgroundColor: "#ffffff",
    width: "50px",
    height: "50px",
    borderRadius: "100%",
    stroke: "#000000",
    marginRight: "20px",
  },
};

const RootCard = () => {
  const [resources, setResources] = useState({
    popular: [],
    trending: [],
    recent: [],
    highestRated: [],
    all: [],
  });
  const [loading, setLoading] = useState(true);
  const [comingSoon, setComingSoon] = useState(false);
  const history = useHistory();
  const category = useParams();

  function CustomArrowNext(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={arrowStyle} onClick={onClick}>
        <ChevronRight style={arrowStyle.iconNext} />
      </div>
    );
  }

  function CustomArrowPrev(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={arrowStyle.prev} onClick={onClick}>
        <ChevronLeft style={arrowStyle.iconPrev} />
      </div>
    );
  }

  function handleCardClick(card) {
    let uid = card.uid;
    let url = card.url;
    let updateResource = { ...resources };
    let updateAllArr = updateResource.all;

    for (let i = 0; i < updateAllArr.length; i++) {
      if (updateAllArr[i].uid === uid) {
        updateAllArr[i].count += 1;
      }
    }
    updateResource.all = updateAllArr;
    setResources(updateResource);
    if (url.indexOf("http://") == 0 || url.indexOf("https://") == 0) {
    } else {
      url = "https://" + url;
    }
    let data = {
      resource: uid,
    };
    putRequest("/resource/count", data)
      .then((res) => {
        window.open(url);
      })
      .catch((err) => console.log(err));
  }

  function goBack() {
    history.push(history.push(`/${category.category}`));
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    nextArrow: <CustomArrowNext />,
    prevArrow: <CustomArrowPrev />,
    responsive: [
      {
        breakpoint: 1610,
        settings: {
          slidesToShow: 4,
          variableWidth: true,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          variableWidth: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          variableWidth: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          variableWidth: true,
        },
      },
    ],
  };

  useEffect(() => {
    let categoryId = category.id;
    if (categoryId === "wIBuYTMa") {
      setComingSoon(true);
    }
    let requests = [
      getRequest(`/resources/popular/${categoryId}`),
      getRequest(`/resources/trending/${categoryId}`),
      getRequest(`/resources/highly-rated/${categoryId}`),
      getRequest(`/resources/recent/${categoryId}`),
    ];

    multiGetRequest(requests).then((res) => {
      let newResources = { ...resources };
      let allResources = [];
      for (let i = 0; i < res.length; i++) {
        for (let j = 0; j < res[i].data.length; j++) {
          allResources.push(res[i].data[j]);
        }
      }
      newResources.popular = res[0].data;
      newResources.trending = res[1].data;
      newResources.highestRated = res[2].data;
      newResources.recent = res[3].data;
      newResources.all = allResources;
      setResources(newResources);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <div className={style.wrapper}>
        {loading ? (
          <div className={style.loader}>
            <CircularProgress color="secondary" size="70px" />
          </div>
        ) : null}

        <SecondaryNav />

        {!loading &&
        resources.popular.length === 0 &&
        resources.trending.length === 0 &&
        resources.highestRated.length === 0 &&
        resources.recent.length === 0 ? (
          <>
            {comingSoon ? (
              <div className={style.teacher}>
                <img src={coming} alt="" />
              </div>
            ) : (
              <div className={style.empty}>
                No websites to show :(
                <Button onClick={goBack} title="Go Back" radius="5" />
              </div>
            )}
          </>
        ) : null}

        {/* Popular Section */}
        {resources.popular.length > 0 ? (
          <div className={style.section}>
            <p className={style.sectionTitle}>Popular</p>
            <div className={style.carouselWrapper} style={{ padding: "0 20px" }}>
              <Slider {...settings}>
                {resources.popular.map((card) => {
                  return (
                    <div key={card.uid} onClick={handleCardClick.bind(this, card)}>
                      {/* slick requires h3 */}
                      <h3>
                        <div key={card.title} className={style.card}>
                          <div className={style.image}>
                            {/* <img src={card.img_url ? `${baseUrl.baseUrl}/${card.img_url}` : placeholder} alt="" /> */}
                            <img src={whiteCard} alt="" />
                            <p className={style.title}>{card.resourceName}</p>
                          </div>
                          <div className={style.body}>
                            <p className={style.info}>{card.description}</p>
                          </div>
                          <div className={style.ratings}>
                            <div className={style.stars}>
                              <StyledRating size="small" name="read-only" precision={0.5} value={card.stars} readOnly />
                            </div>
                            <div className={style.likes}>
                              <Eye />
                              {card.count}
                            </div>
                          </div>
                        </div>
                      </h3>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        ) : null}

        {/* Trending Section */}
        {resources.trending.length > 0 ? (
          <div className={style.section}>
            <p className={style.sectionTitle}>Trending</p>
            <div className={style.carouselWrapper} style={{ padding: "0 20px" }}>
              <Slider {...settings}>
                {resources.trending.map((card) => {
                  return (
                    <div key={card.uid} onClick={handleCardClick.bind(this, card)}>
                      {/* slick requires h3 */}
                      <h3>
                        <div key={card.title} className={style.card}>
                          <div className={style.image}>
                            {/* <img src={card.img_url ? `${baseUrl.baseUrl}/${card.img_url}` : placeholder} alt="" /> */}
                            <img src={greenCard} alt="" />
                            <p className={style.title}>{card.resourceName}</p>
                          </div>
                          <div className={style.body}>
                            <p className={style.info}>{card.description}</p>
                          </div>
                          <div className={style.ratings}>
                            <div className={style.stars}>
                              <StyledRating size="small" name="read-only" precision={0.5} value={card.stars} readOnly />
                            </div>
                            <div className={style.likes}>
                              <Eye />
                              {card.count}
                            </div>
                          </div>
                        </div>
                      </h3>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        ) : null}

        {/* Highest Rated Section */}
        {resources.highestRated.length > 0 ? (
          <div className={style.section}>
            <p className={style.sectionTitle}>Highest Rated</p>
            <div className={style.carouselWrapper} style={{ padding: "0 20px" }}>
              <Slider {...settings}>
                {resources.highestRated.map((card) => {
                  return (
                    <div key={card.uid} onClick={handleCardClick.bind(this, card)}>
                      {/* slick requires h3 */}
                      <h3>
                        <div key={card.title} className={style.card}>
                          <div className={style.image}>
                            {/* <img src={card.img_url ? `${baseUrl.baseUrl}/${card.img_url}` : placeholder} alt="" /> */}
                            <img src={pinkCard} alt="" />
                            <p className={style.title}>{card.resourceName}</p>
                          </div>
                          <div className={style.body}>
                            <p className={style.info}>{card.description}</p>
                          </div>
                          <div className={style.ratings}>
                            <div className={style.stars}>
                              <StyledRating size="small" name="read-only" precision={0.5} value={card.stars} readOnly />
                            </div>
                            <div className={style.likes}>
                              <Eye />
                              {card.count}
                            </div>
                          </div>
                        </div>
                      </h3>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        ) : null}

        {/* Recently Added Section */}
        {resources.recent.length > 0 ? (
          <div className={style.section}>
            <p className={style.sectionTitle}>Recently Added</p>
            <div className={style.carouselWrapper} style={{ padding: "0 20px" }}>
              <Slider {...settings}>
                {resources.recent.map((card) => {
                  return (
                    <div key={card.uid} onClick={handleCardClick.bind(this, card)}>
                      {/* slick requires h3 */}
                      <h3>
                        <div key={card.title} className={style.card}>
                          <div className={style.image}>
                            {/* <img src={card.img_url ? `${baseUrl.baseUrl}/${card.img_url}` : placeholder} alt="" /> */}
                            <img src={blueCard} alt="" />
                            <p className={style.title}>{card.resourceName}</p>
                          </div>
                          <div className={style.body}>
                            <p className={style.info}>{card.description}</p>
                          </div>
                          <div className={style.ratings}>
                            <div className={style.stars}>
                              <StyledRating size="small" name="read-only" precision={0.5} value={card.stars} readOnly />
                            </div>
                            <div className={style.likes}>
                              <Eye />
                              {card.count}
                            </div>
                          </div>
                        </div>
                      </h3>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        ) : null}
      </div>
      <Footer />
    </>
  );
};

export default RootCard;
