import React, { useState } from "react";
import Button from "../shared/Button";
import style from "./Quiz.module.css";

import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Result from "./Result";

const customTheme = createMuiTheme({
  overrides: {
    MuiRadio: {
      colorSecondary: {
        color: "#b83b5e",
        "&$checked": {
          color: "#b83b5e",
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        color: "#666666",
        fontWeight: "00",
      },
    },
  },
});

const QUES_POOL = [
  {
    ques: "lorem lorem ipsum ipsum doler doler sit sit",
    option1: "option 1",
    option2: "option 2",
    option3: "option 3",
    option4: "option 4",
    ans: "option2",
  },
  {
    ques: "lorem lorem ipsum ipsum",
    option1: "option 1",
    option2: "option 2",
    option3: "option 3",
    option4: "option 4",
    ans: "option2",
  },
  {
    ques: "lorem lorem ipsum ipsum",
    option1: "option 1",
    option2: "option 2",
    option3: "option 3",
    option4: "option 4",
    ans: "option2",
  },
  {
    ques: "lorem lorem ipsum ipsum",
    option1: "option 1",
    option2: "option 2",
    option3: "option 3",
    option4: "option 4",
    ans: "option2",
  },
  {
    ques: "lorem lorem ipsum ipsum",
    option1: "option 1",
    option2: "option 2",
    option3: "option 3",
    option4: "option 4",
    ans: "option2",
  },
  {
    ques: "lorem lorem ipsum ipsum",
    option1: "option 1",
    option2: "option 2",
    option3: "option 3",
    option4: "option 4",
    ans: "option2",
  },
  {
    ques: "lorem lorem ipsum ipsum",
    option1: "option 1",
    option2: "option 2",
    option3: "option 3",
    option4: "option 4",
    ans: "option2",
  },
  {
    ques: "lorem lorem ipsum ipsum",
    option1: "option 1",
    option2: "option 2",
    option3: "option 3",
    option4: "option 4",
    ans: "option2",
  },
  {
    ques: "lorem lorem ipsum ipsum",
    option1: "option 1",
    option2: "option 2",
    option3: "option 3",
    option4: "option 4",
    ans: "option2",
  },
];

const Quiz = () => {
  const [option, setOption] = useState("2");
  const [ifSubmit, setIfSubmit] = useState(false);

  function handleOption(event) {
    setOption(event.target.value);
  }

  function handleSubmit() {
    setIfSubmit((prev) => !prev);
  }

  return (
    <div className="App">
      <div className={style.wrapper}>
        <div className={style.quizHeader}>
          <div className={style.top}>
            <p className={style.greeting}>Hi, Shwet</p>
            <p>Timer Component</p>
          </div>
          <div className={style.bottom}>
            <p>Maths Assessment</p>
          </div>
        </div>
        <div className={style.quiz}>
          {QUES_POOL.map((pool) => {
            return (
              <div className={style.quizSection}>
                <p className={style.question}>{pool.ques}</p>
                <div className={style.options}>
                  <FormControl component="fieldset">
                    <MuiThemeProvider theme={customTheme}>
                      <RadioGroup value={option} onChange={handleOption}>
                        <FormControlLabel value="1" control={<Radio />} label={`${pool.option1}`} />
                      </RadioGroup>
                      <RadioGroup value={option} onChange={handleOption}>
                        <FormControlLabel value="2" control={<Radio />} label={`${pool.option2}`} />
                      </RadioGroup>
                      <RadioGroup value={option} onChange={handleOption}>
                        <FormControlLabel value="3" control={<Radio />} label={`${pool.option3}`} />
                      </RadioGroup>
                      <RadioGroup value={option} onChange={handleOption}>
                        <FormControlLabel value="4" control={<Radio />} label={`${pool.option4}`} />
                      </RadioGroup>
                    </MuiThemeProvider>
                  </FormControl>
                </div>
              </div>
            );
          })}

          <div className={style.quizController}>
            {ifSubmit ? (
              <>
                <p>Submited</p>
                <Button
                  className={style.quizButton}
                  title="Re-take Assessment"
                  background="white-pink"
                  radius="25"
                />{" "}
              </>
            ) : (
              <Button
                onClick={handleSubmit}
                className={style.quizButton}
                title="Submit"
                background="pink"
                radius="25"
              />
            )}
          </div>
        </div>
      </div>
      {ifSubmit ? <Result /> : null}
    </div>
  );
};

export default Quiz;
