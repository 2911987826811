import React from "react";
import { ArrowRightCircle } from "react-feather";

const Button = (props) => {
  let background, color, border;
  if (props.background === "pink") {
    background = "#f26666";
    color = "#ffffff";
    border = "#f26666";
  } else if (props.background === "white-pink") {
    background = "#ffffff";
    color = "#f26666";
    border = "#f26666";
  } else {
    background = "#f26666";
    color = "#ffffff";
    border = "#f26666";
  }

  const buttonStyle = {
    backgroundColor: `${background}`,
    color: `${color}`,
    outline: "none",
    width: "120px",
    height: "40px",
    border: `1px solid ${border}`,
    borderRadius: `${props.radius}px`,
    fontSize: "1rem",
    fontWeight: "500",
    letterspacing: "1px",
    cursor: "pointer",
  };
  return (
    <button className={props.className} style={buttonStyle} onClick={props.onClick} id={props.id}>
      {props.title} {props.arrow ? <ArrowRightCircle /> : null}
    </button>
  );
};

export default Button;
