import axios from "axios";
import { baseUrl } from "./config.json";

export const postRequest = (path, data) => {
  return axios.post(`${baseUrl}${path}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getRequest = (path) => {
  return axios.get(`${baseUrl}${path}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const putRequest = (path, data) => {
  return axios.put(`${baseUrl}${path}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const multiGetRequest = (paths) => {
  return axios.all(paths, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
